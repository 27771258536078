<template>
  <v-form
    @submit.stop.prevent="checkForm()"
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
    class="main-content"
  >
    <div class="content">
      <div class="header-container">
        <span class="header-title">{{ $t("your_account_tems") }}</span>
        <span class="header-subtitle">
          {{ $t(`confirm_terms_sub_tech`) }}
        </span>
      </div>
      <div class="step-container">
        <span class="step">{{
          $t("alpha_register_step_count", { step: 4, totalSteps: 6 })
        }}</span>
        <div class="progress" />
      </div>
      <div class="card">
        <div class="items-center">
          <div class="terms-content">
            <v-dialog persistent v-model="terms">
              <TermosPDFModal
                type="terms"
                :Header="$t('terms_use')"
                :Html="contractHtmlTerms"
                :Subtitle="contractAgentTerms"
                @agree="agree_terms"
                @decline="decline_terms"
              />
            </v-dialog>
            <v-checkbox
              data-test="Alpha:Contracts:terms"
              style="background-color: var(--white)"
              :rules="[required]"
              class="mt-12 pa-1 mb-3 alpha-checkbox"
              @click="open_terms"
              hide-details
              readonly
              v-model="User.Terms"
            >
              <template v-slot:label>
                <span style="color: var(--dark); font-size: 16px"
                  >{{ $t("read_agreed")
                  }}<a class="ml-1" @click="open_terms"
                    ><strong
                      style="color: var(--dark); text-decoration: underline"
                      >{{ $t("terms_use") }}</strong
                    ></a
                  ></span
                >
              </template></v-checkbox
            >

            <v-dialog persistent v-model="signedPortfolio">
              <SignedPortfolioPDFModal
                type="signedPortfolio"
                :Header="$t('signed_portfolio')"
                :Html="contractHtmlManaged"
                :Subtitle="contractAgentManaged"
                @agree="agree_signed_portfolio"
                @decline="decline_signed_portfolio"
              />
            </v-dialog>
            <v-checkbox v-if="User.Nationality=='Brazil'"
              data-test="Alpha:Contracts:signedPortfolio"
              :rules="[required]"
              class="mt-0 pa-1 mb-3 alpha-checkbox"
              @click="open_signed_portfolio"
              style="background-color: var(--white)"
              hide-details
              readonly
              v-model="User.SignedPortfolio"
            >
              <template v-slot:label>
                <span style="color: var(--dark); font-size: 16px"
                  >{{ $t("read_agreed")
                  }}<a class="ml-1" @click="open_signed_portfolio"
                    ><strong
                      style="color: var(--dark); text-decoration: underline"
                      >{{ $t("signed_portfolio") }}</strong
                    ></a
                  ></span
                >
              </template></v-checkbox
            >

            <v-dialog persistent v-model="signedAlphaPortfolio">
              <SignedAlphaPortfolioPDFModal
                type="signedAlphaPortfolio"
                :Html="contractHtmlAddendum"
                :Header="
                  $t('alpha_register_contract', {
                    portfolioNumber: signedPortfolioNumber,
                  })
                "
                :Subtitle="contractAgentAddendum"
                @agree="agree_signed_alpha_portfolio"
                @decline="decline_signed_alpha_portfolio"
              />
            </v-dialog>
            <v-checkbox
              v-if="User.InvestValue >= alphaPortfolioMinInvestValue"
              data-test="Alpha:Contracts:signedAlphaPortfolio"
              :rules="[required]"
              class="my-0 pa-1 mb-8 alpha-checkbox"
              style="background-color: var(--white)"
              @click="open_signed_alpha_portfolio"
              hide-details
              readonly
              v-model="User.SignedAlphaPortfolio"
            >
              <template v-slot:label>
                <span style="color: var(--dark); font-size: 16px"
                  >{{ $t("read_agreed")
                  }}<a class="ml-1"
                    ><strong
                      style="color: var(--dark); text-decoration: underline"
                      >{{
                        $t("alpha_register_contract", {
                          portfolioNumber: signedPortfolioNumber,
                        })
                      }}</strong
                    ></a
                  ></span
                >
              </template></v-checkbox
            >
          </div>
          <div>
            <div class="privacy-container">
              <span
                >{{ $t("to_more_info")
                }}<a @click="openPrivacyPolicy">{{ $t("privacy_policy") }}</a>
              </span>
              <p>{{ $t("dont_worry") }}</p>
              <p style="color: red; margin: 0px" v-if="isContractSign == false">
                {{ $t("need_to_sign") }}
              </p>
            </div>
          </div>
          <div class="button-next-step-container">
            <DxaButton
              data-test="Alpha:ContractsComponent:Next"
              @function="checkForm"
              :title="$t('Agree_continue')"
              class="mx-auto my-1 mb-3 btn-continue"
              style="width: 270px; margin: 0 !important"
            ></DxaButton>
          </div>
        </div>
      </div>
    </div>
  </v-form>
</template>
<script>
import ApiService from "../../../../../../../../services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import TermosPDFModal from "@/components/dxa/pdfModal/PdfModal";
import SignedPortfolioPDFModal from "@/components/dxa/pdfModal/PdfModal";
import SignedAlphaPortfolioPDFModal from "@/components/dxa/pdfModal/PdfModal";
import DxaButton from "@/components/dxa/Button/DxaButton.vue";
import { contractTypesEnum } from "@/shared/enums/ContractTypesEnum.js";
import { openPrivacyPolicy } from "@/shared/helpers/openPrivacyPolicyTerm";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";
import UserService from "@/services/UserService";
import moment from "moment";

export default {
  created() {
    this.getPortfolioByValue();
    this.isContractSign = true;
    this.clickToNext = false;
    this.fetchAllContractsHtml(this.$i18n.locale);
  },
  components: {
    TermosPDFModal,
    SignedPortfolioPDFModal,
    SignedAlphaPortfolioPDFModal,
    DxaButton,
  },
  props: {
    Portfolio: Object,
    User: Object,
    PrivatePartner: { type: Boolean, default: false },
  },
  data() {
    return {
      documentTypeEnum: DocumentTypeEnum,
      gs: new GeneralServices(),
      apiService: new ApiService(),
      valid: true,
      portfolioCard: null,
      termsOpened: false,
      terms: false,
      signedAlphaPortfolio: false,
      alphaPortfolioOpened: false,
      signedPortfolio: false,
      signedPortfolioOpened: false,
      signedAlphaPortfolioOpened: false,
      signedPortfolioNumber: null,
      lazy: false,
      date: null,
      alert: null,
      error: null,
      clickToNext: false,
      isContractSign: false,
      contractTermHtml: "",
      contractTermAgent: "",
      contractManagedHtml: "",
      contractManagedAgent: "",
      contractAddedumHtml: "",
      contractAddedumAgent: "",
      openPrivacyPolicy: openPrivacyPolicy,
      CurrencyEnum: CurrencyEnum,
      alphaPortfolioMinInvestValue: 200000,
      JuridicalTypeEnum,
      userService: new UserService(),
    };
  },
  methods: {
    async getPortfolioByValue() {
      this.portfolioCard = {
        activeWidth: "420",
        activeHeight: "240",
        cardTitle: null,
        cardSubTitle: "private_partner_card_sub_title",
        cardPlanedValue: null,
        walletItemsList: null,
        isAlpha: true,
      };
      this.loading = true;
      await this.apiService
        .getRequest(`portfolio/get-by-value/${this.User.InvestValue}`, false)
        .then((response) => {
          this.signedPortfolioNumber = response.content.number;
          this.User.SelectedPortfolioId = response.content.id;
        })
        .catch((error) => {
          return error;
        });

      this.loading = false;
    },
    get_full_address() {
      var stringBuilder = `${this.User.Address}, ${this.User.AddressNumber}`;
      if (this.User.AddressComplement) {
        stringBuilder += `, ${this.User.AddressComplement}`;
      }
      return stringBuilder;
    },
    agree_terms() {
      this.User.Terms = true;
      this.terms = false;
    },
    decline_terms() {
      this.User.Terms = false;
      this.terms = false;
    },
    terms_changed() {
      if (!this.termsOpened) {
        this.open_terms();
      }
    },
    get_terms() {
      var env_url = process.env.VUE_APP_BASE_URL;
      return env_url + `Documents/terms_of_use_${this.$i18n.locale}.pdf`;
    },
    open_terms() {
      this.User.Terms = false;
      this.terms = true;
      this.termsOpened = true;
    },
    agree_signed_portfolio() {
      this.User.SignedPortfolio = true;
      this.signedPortfolio = false;
    },
    decline_signed_portfolio() {
      this.User.SignedPortfolio = false;
      this.signedPortfolio = false;
    },
    signed_portfolio_changed() {
      if (!this.signedPortfolioOpened) {
        this.open_signed_portfolio();
      }
    },
    get_signed_portfolio() {
      var env_url = process.env.VUE_APP_BASE_URL;
      return env_url + `Documents/carteira_assinada_${this.$i18n.locale}.pdf`;
    },
    open_signed_portfolio() {
      this.User.SignedPortfolio = false;
      this.signedPortfolio = true;
      this.signedPortfolioOpened = true;
    },
    agree_signed_alpha_portfolio() {
      this.User.SignedAlphaPortfolio = true;
      this.signedAlphaPortfolio = false;
    },
    decline_signed_alpha_portfolio() {
      this.User.SignedAlphaPortfolio = false;
      this.signedAlphaPortfolio = false;
    },
    signed_alpha_portfolio_changed() {
      if (!this.signedAlphaPortfolioOpened) {
        this.open_signed_alpha_portfolio();
      }
    },
    get_signed_alpha_portfolio() {
      var env_url = process.env.VUE_APP_BASE_URL;
      return env_url + `Documents/carteira_alpha_${this.Portfolio.number}.pdf`;
    },
    open_signed_alpha_portfolio() {
      this.User.SignedAlphaPortfolio = false;
      this.signedAlphaPortfolio = true;
      this.signedAlphaPortfolioOpened = true;
    },

    async fetchAllContractsHtml(language) {
      let contractTerm = await this.userService.getContractHtml(
        contractTypesEnum.TERMSOFUSE,
        language
      );
      let contractManaged = await this.userService.getContractHtml(
        contractTypesEnum.MANAGEDPORTFOLIO,
        language
      );
      let contractAddedum = await this.userService.getContractHtml(
        contractTypesEnum.ALPHAADDENDUM,
        language
      );

      this.contractTermHtml = contractTerm.content;
      this.contractManagedHtml = contractManaged.content;
      this.contractAddedumHtml = contractAddedum.content;

      this.contractTermAgent = contractTerm.agent;
      this.contractManagedAgent = contractManaged.agent;
      this.contractAddedumAgent = contractAddedum.agent;
    },

    checkForm: function () {
      var result = this.$refs.form.validate();
      this.clickToNext = true;

      if (result) {
        this.isContractSign = true;
        this.$gtag.event("contracts", {
          event_category: "investor_register",
          event_label: "contracts",
          value: 1,
        });
        this.$emit("next_step");
      } else {
        this.isContractSign = false;

        return;
      }
    },
  },
  computed: {
    isContractsSign() {
      return (
        this.User.Terms &&
        this.User.SignedAlphaPortfolio &&
        this.User.SignedPortfolio
      );
    },
    required() {
      return (value) => !!value || this.$t("required");
    },

    contractHtmlTerms() {
      return this.contractTermHtml;
    },
    contractAgentTerms(){
      return this.contractTermAgent;
    },

    contractHtmlManaged() {
      return this.contractManagedHtml
        .replace("{date}", moment().format("DD"))
        .replace("{month}", this.$t("month_" + moment().month()))
        .replace("{year}", moment().format("YYYY"));
    },
    contractAgentManaged(){
      return this.contractManagedAgent;
    },

    contractHtmlAddendum() {
      return this.contractAddedumHtml
        .replace("{name}", this.User.Name)
        .replace("{lastName}", this.User.LastName)
        .replace(
          "{docType}",
          this.User.DocumentType == this.documentTypeEnum.cpf
            ? "CPF"
            : "passport"
        )
        .replace("{socialNumber}", this.User.SocialNumber)
        .replace("{date}", moment().format("DD"))
        .replace("{month}", this.$t("month_" + moment().month()))
        .replace("{year}", moment().format("YYYY"));
    },
    contractAgentAddendum(){
      return this.contractAddendumAgent;
    },
  },
};
</script>

<style scoped>
.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.header-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.step-container {
  margin-top: 35px;
}

.step {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.progress {
  height: 6px;
  background: var(--primary);
  border-radius: 12px;
  width: 66%;
}

.card {
  padding: 0px 35px 35px 35px;
  gap: 12px;
  background: var(--white);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-width: 1020px !important;
}

.items-center {
  display: grid;
  place-items: center;
}

/* .privacy-container {
  margin-top: 24px;
} */

.button-next-step-container {
  margin-top: 24px;
}

.terms-content {
  display: flex;
  flex-direction: column;
}

.btn-continue {
  margin-top: 4.5rem !important;
  align-self: flex-end;
}

@media (max-width: 1020px) {
  .card {
    min-width: initial !important;
  }
}

@media (max-width: 768px) {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .step-container {
    display: none;
  }

  .card {
    margin-top: 12px;
    flex-direction: column;
    min-width: initial !important;
  }

  .terms-content {
    margin-left: 0;
  }
}

@media (max-width: 320px) {
  .btn-continue {
    margin-top: 4.5rem !important;
    align-self: center;
  }
}
</style>
