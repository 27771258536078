<template>
  <div class="select-portfolio-bg">
    <h2 class="select-portfolio-title" v-if="PrivatePartner">
      {{ $t(`alpha_register_2_header_tech`) }}
    </h2>
    <h2 class="select-portfolio-title" v-else>
      {{ $t(`alpha_register_2_header`) }}
    </h2>
    <h3 class="select-portfolio-subtitle">
      {{ $t(`alpha_register_2_subheader`) }}
    </h3>
    <div class="items-center">
      <ul class="select-portfolio-content-list">
        <li>
          <v-icon small color="primary">mdi-check</v-icon>
          <p class="select-portfolio-content-list-item">
            {{ $t("alpha_register_suggestions_allocations") }}
          </p>
        </li>
        <li v-if="PrivatePartner">
          <v-icon small color="primary">mdi-check</v-icon>
          <p class="select-portfolio-content-list-item">
            {{ $t("regulated_manager_cvm") }}
          </p>
        </li>
        <li v-if="PrivatePartner">
          <v-icon small color="primary">mdi-check</v-icon>
          <p class="select-portfolio-content-list-item">
            {{ $t("exclusive_opportunities") }}
          </p>
        </li>
        <li v-if="!PrivatePartner">
          <v-icon small color="primary">mdi-check</v-icon>
          <p class="select-portfolio-content-list-item">
            {{ $t("alpha_register_plan_investment") }}
          </p>
        </li>
        <li v-if="!PrivatePartner">
          <v-icon small color="primary">mdi-check</v-icon>
          <p class="select-portfolio-content-list-item">
            {{ $t("alpha_register_exclusive_management") }}
          </p>
        </li>
        <li v-if="!PrivatePartner">
          <v-icon small color="primary">mdi-check</v-icon>
          <p class="select-portfolio-content-list-item">
            {{ $t("alpha_register_active_governance") }}
          </p>
        </li>
        <li v-if="!PrivatePartner">
          <v-icon small color="primary">mdi-check</v-icon>
          <p class="select-portfolio-content-list-item">
            {{ $t("alpha_register_start_investment") }}
          </p>
        </li>
      </ul>
    </div>
    <div class="end-box-center">
      <div class="pa-5 end-box">
        <div>
          <v-btn
            id="btn-create-wallet"
            data-test="Alpha:SelectPortfolio:Next"
            :loading="loading"
            @click="nextStep"
            class="ma-0 primary"
            style="border-radius: 15px; font-size: 24px"
          >
            {{ $t("alpha_register_create_portfolio") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#slider {
  margin-top: 0;
}

#btn-create-wallet {
  width: 333px;
}

.no-background {
  background-color: rgba(211, 37, 37, 0.007);
}

.v-application.mt-10 {
  margin-top: 4px !important;
}

.v-input--checkbox::v-deep {
  .v-label {
    color: var(--dark);
  }
}

.select-portfolio-bg {
  background-color: var(--white) !important;
  width: 100%;
  height: 100vh;
}

.select-portfolio-content-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  margin-bottom: 40px;
}

.select-portfolio-content-list > li + li {
  margin-top: 10px;
}

.select-portfolio-content-list li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.select-portfolio-content-list li > p {
  margin: 0;
  padding: 0;
}

.select-portfolio-content-list-item {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.grid-item-end {
  align-self: end;
}

.select-portfolio-title {
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 0.5rem;
  text-align: center;
  padding: 0 20px !important;
  margin-top: 40px;
}

.select-portfolio-subtitle {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-bottom: 40px;
  text-align: center;
  padding: 0 20px !important;
}

.select-portfolio-content-list-item {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.select-portfolio-content-list {
  padding: 0 20px !important;
}

.select-portfolio-content-list > div li + li {
  margin-top: 10px !important;
}

.end-box {
  display: grid;
  place-items: center;
}

.end-box-center {
  display: grid;
  place-items: center;
}

@media only screen and (max-width: 340px) {
  #btn-create-wallet {
    max-width: max-content;
  }
}
</style>
<style>
.v-slide-groupwrapper {
  overflow-x: auto; /* Enables the horizontal scrollbar /
  / Next lines hides scrollbar in different browsers for styling purposes /
  -ms-overflow-style: none; / IE and Edge /
  scrollbar-width: none; / Firefox */
}
.v-slide-groupwrapper::-webkit-scrollbar {
  display: none; /* Chrome opera and Safary */
  width: 0px;
  background: transparent;
}

.v-slide-group__content {
  white-space: unset !important;
}

.portfolio-selected-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.portfolio-selected-info {
  display: flex;
  flex-direction: column;
}

.portfolio-selected-info-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-top: 8px;
}

.investment-deadline-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.value-to-invest-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.line-bar {
  border-bottom: 1px solid #9c9c9c;
  width: 100%;
  margin-top: 8px;
}

.card-portfolio-content {
  width: 100%;
}

.card-portfolio-selected {
  display: flex;
  width: 347px;
  border: none;
  border-left: 20px solid var(--primary);
  border-radius: 10px;
}

.items-center {
  display: grid;
  place-items: center;
}
</style>
<style>
.v-slide-groupwrapper {
  overflow-x: auto; /* Enables the horizontal scrollbar /
  / Next lines hides scrollbar in different browsers for styling purposes /
  -ms-overflow-style: none; / IE and Edge /
  scrollbar-width: none; / Firefox */
}
.v-slide-groupwrapper::-webkit-scrollbar {
  display: none; /* Chrome opera and Safary */
  width: 0px;
  background: transparent;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationServices from "../../../../../../../services/ValidationService";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

export default {
  name: "SelectPortfolio",
  components: {},
  data: () => ({
    vs: new ValidationServices(),
    gs: new GeneralServices(),
    apiService: new ApiService(),
    model: null,
    error: null,
    cardWidth: 333,
    cardHeight: 190,
    CurrencyEnum: CurrencyEnum,
    dataWallets: [],
  }),
  props: {
    PortfolioOptions: Array,
    PrivatePartner: Boolean,
  },
  created() {},
  beforeDestroy() {},
  watch: {},
  methods: {
    nextStep() {
      this.$gtag.event("select_portfolio", {
        event_category: "investor_register",
        event_label: "select_portfolio",
        value: this.step,
      });

      this.$emit("next");
    },
  },
};
</script>
