<template>
  <div
    class="flexing d-flex justify-end"
    style="
      margin-bottom: 0px;
      height: 100vh;
      padding-top: 75px;
      background-color: var(--dark);
    "
  >
    <div class="nav-bar">
      <v-card
        class="ma-0 pa-0"
        style="
          position: absolute;
          top: 10px;
          left: 10%;
          display: flex;
          flex-direction: row;
          background-color: var(--dark);
          box-shadow: none !important;
        "
        elevation="0"
      >
        <v-img
          style="width: 100px !important"
          class="mx-2"
          src="@/assets/dxa-white.png"
        >
        </v-img>
        <span style="color: var(--primary); font-size: 28px">+</span>
        <v-img
          style="width: 110px !important"
          class="mx-2 m"
          v-if="partnerLogo"
          :src="gs.get_photo_path(partnerLogo)"
        />
      </v-card>
    </div>
    <div style="max-width: 420px; padding-right: 10%">
      <span
        class=""
        style="font-weight: bold; color: var(--white); font-size: 31px"
      >
        {{ $t(`portfolio_created_successfully`) }}
      </span>
      <br />

      <p
        style="
          color: var(--white);
          font-size: 14px;
          margin-top: 15px;
          width: 290px;
        "
      >
        {{ $t(`portfolio_created_message`) }}
      </p>
      <br />
      <p style="color: var(--primary); font-size: 16px; font-weight: bold">
        {{ $t("next_step") + ":" }}
      </p>
      <p>
        <v-icon color="primary">mdi-checkbox-blank-circle</v-icon>
        <span style="color: var(--white); font-weight: 700">{{
          $t("your_first_check_in")
        }}</span>
      </p>
      <p style="color: var(--white); font-weight: 700">
        <v-icon color="white">mdi-calendar-blank-outline</v-icon>
        {{ parseDate(date) }}
      </p>
      <p style="color: var(--white); font-weight: 700">
        <v-icon color="white">mdi-timer-outline</v-icon>
        {{ formatHour(hour) }}
      </p>

      <p style="color: var(--white); margin-bottom: 36px">
        {{ $t("invite_send_to_email") }}
      </p>
      <!-- <v-btn
        @click="goToLogin"
        class="text-center btn-gradient primary"
        style="
          border-radius: 16px;
          z-index: 10;
          box-shadow: 0px 4px 20px #01d181;
          width: 250px;
          display: block;
          height: 47px;
        "
        ><span style="font-size: 16px; font-weight: bold">
          {{ $t("Access_dxa") }}
        </span>
      </v-btn> -->
      <DxaButton
        @function="goToLogin"
        :title="$t('Access_dxa')"
        style="width: 250px"
      ></DxaButton>
      <br />
    </div>
    <v-img
      style="position: absolute; top: 0; left: 0; z-index: 1 !important"
      max-width="85%"
      src="../../../../../../../../assets/dxa-background-welcome-page2.png"
    ></v-img>
    <v-img
      style="position: absolute; bottom: 25px; left: 25px"
      max-width="45%"
      src="../../../../../../../../assets/dxa-background-welcome-page3.png"
    ></v-img>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
.finish {
  z-index: 100;
}
.nav-bar {
  background-color: var(--dark);
  height: 70px;
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 2;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import DxaButton from "@/components/dxa/Button/DxaButton.vue";
export default {
  name: "Finish",
  components: {
    DxaButton,
  },
  filters: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: true,
    termsOpened: false,
    terms: false,
    lazy: false,
    date: null,
    alert: null,
    error: null,
    portfolioSelected: null,
    investorCreated: null,
    hour: null,
  }),
  async created() {
    this.portfolioSelected = this.Portfolios.filter(
      (x) => x.id == this.User.SelectedPortfolioId
    )[0];

    this.getInvestorCreated();
  },
  props: {
    User: Object,
    Portfolios: Array,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    parseDate(date) {
      moment.locale("pt-br");
      if (!date) return null;
      let adjustDate = moment(date).format("LLLL");
      adjustDate = adjustDate.split("à");
      return adjustDate[0];
    },
    formatHour(hour) {
      return moment(hour).format("LT");
    },
    async getInvestorCreated() {
      this.apiService
        .getRequest(`user/get-calendly-from-user/${this.User.Email}`)
        .then((response) => {
          this.investorCreated = response.content;
          this.hour = this.investorCreated;
          this.investorCreated = this.investorCreated.split("T");
          this.date = this.investorCreated[0];
        })
        .catch((error) => {
          return error;
        });
    },
    goToLogin() {
      this.$router.push("/auth");
    },
  },
};
</script>
