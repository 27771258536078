export const alphaStepsEnum = {
  WELCOME: 1,
  INVESTORINFO: 2,
  SELECTPORTFOLIO: 3,
  PREFERENCES: 4,
  CONTRACTS: 5,
  CONFIRMDATA: 6,
  DATEPICKER: 7,
  FINISH: 8,
};
