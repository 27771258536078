<template>
  <div
    style="
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 36px;
      background-color: var(--dark);
    "
  >
    <v-img
      style="max-height: 68px"
      src="../../../../../../../assets/DXA_Invest-logo.png"
    ></v-img>
    <div style="margin-top: 50px">
      <span
        class=""
        style="font-weight: bold; color: var(--white); font-size: 31px"
      >
        {{ $t(`portfolio_created_successfully`) }}
      </span>
      <br />

      <p
        style="
          color: var(--white);
          font-size: 14px;
          margin-top: 15px;
          width: 250px;
        "
      >
        {{ $t(`portfolio_created_message`) }}
      </p>
      <br />
      <p style="color: var(--primary); font-size: 16px; font-weight: bold">
        {{ $t("next_step") + ":" }}
      </p>
      <p>
        <v-icon color="primary">mdi-checkbox-blank-circle</v-icon>
        <span style="color: var(--white); font-weight: 700">{{
          $t("your_first_check_in")
        }}</span>
      </p>
      <p style="color: var(--white); font-weight: 700">
        <v-icon color="white">mdi-calendar-blank-outline</v-icon>
        {{ parseDate(date) }}
      </p>
      <p style="color: var(--white); font-weight: 700">
        <v-icon color="white">mdi-timer-outline</v-icon>
        {{ formatHour(hour) }}
      </p>
      <p style="color: var(--white); margin-bottom: 36px">
        {{ $t("invite_send_to_email") }}
      </p>
      <br />
    </div>
    <div style="display: flex; justify-content: center">
      <v-btn
        @click="goToLogin"
        class="text-center btn-gradient primary"
        style="
          border-radius: 16px;
          z-index: 10;
          box-shadow: 0px 4px 20px var(--primary);
          width: 292px;
          display: block;
          height: 47px;
        "
        ><span style="font-size: 16px; font-weight: bold">
          {{ $t("Access_dxa") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
export default {
  name: "Finish",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: true,
    termsOpened: false,
    terms: false,
    lazy: false,
    date: null,
    alert: null,
    error: null,
    portfolioSelected: null,
    investorCreated: null,
    hour: null,
  }),
  async created() {
    this.portfolioSelected = this.Portfolios.filter(
      (x) => x.id == this.User.SelectedPortfolioId
    )[0];
    this.getInvestorCreated();
  },
  props: {
    User: Object,
    Portfolios: Array,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    async getInvestorCreated() {
      this.apiService
        .getRequest(`user/get-calendly-from-user/${this.User.Email}`)
        .then((response) => {
          this.investorCreated = response.content;
          this.hour = this.investorCreated;
          this.investorCreated = this.investorCreated.split("T");
          this.date = this.investorCreated[0];
        })
        .catch((error) => {
          return error;
        });
    },
    parseDate(date) {
      moment.locale("pt-br");
      if (!date) return null;
      let adjustDate = moment(date).format("LLLL");
      adjustDate = adjustDate.split("à");
      return adjustDate[0];
    },
    formatHour(hour) {
      return moment(hour).format("LT");
    },
    goToLogin() {
      this.$router.push("/auth");
    },
  },
};
</script>
