<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <div id="calendly-parent" style="height: 500px" class="ma-3">
        <div
          data-test="calendly-iframe"
          class="calendly-inline-widget"
          data-auto-load="false"
          data-processed="true"
        ></div>
      </div>
    </v-form>
    <v-alert class="ma-2" v-if="alert" type="error">
      {{ alert }}
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
export default {
  name: "DatePicker",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: true,
    termsOpened: false,
    terms: false,
    lazy: false,
    date: null,
    alert: null,
    error: null,
  }),
  async created() {},
  async mounted() {
    var name = this.gs.show_name(this.User);
    var calendlyUrl = process.env.VUE_APP_CALENDLY_ALPHA_URL;
    if (!calendlyUrl) {
      calendlyUrl = "https://calendly.com/rcaram/30min";
    }
    Calendly.initInlineWidget({
      url: `${calendlyUrl}?primary_color=01d281&hide_event_type_details=1&hide_landing_page_details=1`,
      parentElement: document.getElementById("calendly-parent"),
      prefill: {
        name: name,
        birth: this.User.Birth,
        cpf: this.User.SocialNumber,
        email: this.User.Email,
        portfolio: this.User.SelectedPortfolio,
        investValue: this.User.InvestValue,
      },
      utm: {},
    });
    window.addEventListener("message", (e) => {
      if (this.isCalendlyEvent(e)) {
        if (e.data.event == "calendly.event_scheduled") {
          this.$emit("next", e.data.payload.event.uri);
        }
      }
    });
  },
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf("calendly") === 0;
    },
  },
};
</script>
