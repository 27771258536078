<template>
  <div
    style="
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 100vh;
    "
  >
    <div style="align-self: center">
      <h1
        class="confirm-data-title"
        style="font-weight: bold; margin-top: 30px"
      >
        {{ $t("confirm_data_alpha") }}
      </h1>
      <p class="my-5 mb-12 confirm-data-subtitle">
        {{ $t("confirm_for_your_security") }}
      </p>
      <p class="step-count-title">
        {{ $t("step_count", { current: 5, last: 6 }) }}
      </p>
      <v-progress-linear
        color="primary"
        style="width: 200px"
        rounded
        height="6"
        value="200"
      ></v-progress-linear>
    </div>
    <div style="display: flex; width: 1110px; max-width: 100%">
      <v-card
        class="pa-5 pt-8 ma-0"
        elevation="1"
        style="
          height: 550px;
          padding-bottom: 50px !important;
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
          width: 100%;
        "
        color="white"
      >
        <v-form
          @submit.stop.prevent="verifyCode()"
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <div v-if="confirmData == false">
            <v-dialog max-width="501" v-model="dialog" max-height="463"
              ><v-card
                class="success-card pb-5 pt-5"
                style="
                  background-color: var(--white);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                "
              >
                <div style="display: flex; justify-content: end; float: right">
                  <v-btn
                    data-test="Alpha:ConfirmData:BtnContinue"
                    @click="goToPipeline()"
                    class="pa-5 pb-0"
                    width="50px"
                    x-small
                    elevation="0"
                    style="background-color: var(--white); display: flex"
                    ><v-icon color="black">mdi-close</v-icon></v-btn
                  >
                </div>
                <v-icon
                  size="100"
                  x-large
                  color="primary"
                  class="pb-0 pt-0"
                  style="border-radius: 20px"
                  >mdi-check-circle</v-icon
                >
                <span class="py-0 px-4 validated-account-header">{{
                  $t("validated_account_header")
                }}</span>
                <p class="px-5 validated-account-text">
                  {{ $t("validated_account_text") }}
                </p>
                <v-btn
                  data-test="Alpha:ConfirmData:ConfirmData"
                  id="button-finish-confirm"
                  class="px-5 mx-5 my-5"
                  outlined
                  @click="goToPipeline()"
                  color="primary"
                >
                  {{ $t("continue") }}
                </v-btn>
              </v-card>
            </v-dialog>
            <v-row class="ma-0">
              <span
                class="message-sent-email"
                v-html="
                  $t('message_sent_email_and_cellphone', {
                    email: email_msg,
                    cellphone: number_msg,
                  })
                "
              >
              </span>
            </v-row>
            <div class="ma-0 mt-12" style="margin-bottom: 111px !important">
              <span class="confirm-data-label">{{ $t("pin") }}</span>
              <v-text-field
                data-test="Alpha:ConfirmData:ConfirmPin"
                style="max-width: 325px"
                dense
                rounded
                v-mask="['####']"
                :rules="[required]"
                outlined
                v-model="code"
                class="mb-8 confirm-data-input my-input-style"
                label="####"
              ></v-text-field>
              <a v-if="showResendMessage" class="try-again-text">{{
                $t("try_again_in", { countDown: secondsToRetry })
              }}</a>
              <a
                :class="[isDisabled ? 'disabled' : '']"
                class="link-resend-code"
                style="
                  text-decoration: none;
                  margin-top: 10px;
                  margin-bottom: 10px;
                "
                @click="resendCode"
              >
                {{ $t("re_send_code") }}
              </a>

              <p></p>
            </div>
            <!-- <v-btn
              :disabled="!code"
              class="primary ma-0 mt-12"
              type="submit"
              style="border-radius: 15px; width: 267px"
              :loading="next_loading"
              ><h1>{{ $t("confirm_data_alpha") }}</h1></v-btn
            > -->
            <DxaButton
              data-test="Alpha:ConfirmData:ConfirmDataAlpha"
              class="dxa-button"
              type="submit"
              :loading="loading"
              :title="$t('confirm')"
            ></DxaButton>
          </div>
        </v-form>
        <div
          v-if="confirmData"
          style="display: flex; flex-direction: column; justify-content: center"
        >
          <!-- <h1 class="mb-4">{{ $t("confirm_data") }}</h1> -->
          <p class="confirm-data-title-form">
            {{ $t("confirm_data_new_text") }}
          </p>
          <v-row class="ma-0">
            <v-col cols="12" style="padding-left: 0">
              <span class="confirm-data-label">{{
                $t("single_celphone")
              }}</span>
              <v-text-field
                class="confirm-data-input my-input-style"
                @keydown.space.prevent
                dense
                outlined
                v-mask="
                  User.CountryCode && User.CountryCode.callingCodes[0] == '55'
                    ? '+55 (##) #####-####'
                    : `+${User.CountryCode.callingCodes[0]} ##########`
                "
                :rules="[required]"
                hide-details
                v-model="User.CelPhone"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" style="padding-left: 0">
              <span class="confirm-data-label">{{ $t("email") }}</span>

              <v-text-field
                class="confirm-data-input my-input-style"
                @keydown.space.prevent
                color="primary"
                dense
                outlined
                :rules="[required]"
                v-model="User.Email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col style="padding-left: 0">
              <v-btn
                data-test="Alpha:ConfirmData:NextStep"
                class="ma-0 mb-5 primary button-confirm-data"
                :class="loading ? 'disabled' : ''"
                :loading="loading"
                @click="createUserFromPartner()"
                ><h1>{{ this.$t("confirm") }}</h1></v-btn
              >
            </v-col>
          </v-row>
        </div></v-card
      ><v-card
        class="cardsHeight ma-0 pa-5"
        style="
          height: 550px;
          display: flex;
          flex-direction: column;
          place-content: space-between;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          justify-content: space-between;
          background-color: var(--dark);
        "
      >
        <div style="display: flex; justify-content: end">
          <img
            style="max-width: 50px; opacity: 0"
            src="../../../../../../../../assets/dxa-x.png"
            alt=""
          />
        </div>
        <div>
          <img
            style="
              max-height: 226px;

              transform: scaleX(-1);
            "
            src="../../../../../../../../assets/dxa-background-welcome-page3(1).png"
            alt=""
          />
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import DxaButton from "@/components/dxa/Button/DxaButton.vue";
import { saveUserLocalData } from "@/shared/helpers/authHelpers";
import { LanguageEnum } from "@/shared/enums/LanguageEnum";
import { InvestIntendedPersonTypeEnum } from "@/shared/enums/InvestIntendedPersonTypeEnum";
import mixpanel from "mixpanel-browser";

export default {
  components: { DxaButton },
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  data() {
    return {
      apiService: new ApiService(),
      disabledEdition: true,
      confirmData: true,
      editData: false,
      disabled: true,
      loading: false,
      valid: true,
      lazy: false,
      alert: null,
      error: null,
      email_msg: null,
      findArroba: null,
      number_msg: null,
      code: null,
      dialog: false,
      isDisabled: true,
      secondsToRetry: 15,
      showResendMessage: false,
      userId: null,
      InvestIntendedPersonTypeEnum,
    };
  },
  async created() {
    this.codifiedEmailAndNumber();
    this.isDisabled = false;
    this.disabledLanguageMenuEvent();
  },
  methods: {
    enableLanguageMenu() {
      const event = new Event("enableLanguageMenu");
      window.dispatchEvent(event);
    },
    disabledLanguageMenuEvent() {
      const event = new Event("disableLanguageMenu");
      window.dispatchEvent(event);
    },
    countDownTimer() {
      setInterval(() => {
        this.secondsToRetry -= 1;
        if (this.secondsToRetry === 0) {
          clearInterval();
          this.isDisabled = false;
          this.showResendMessage = false;
        }
      }, 1000);
      clearInterval();
    },
    goToPipeline() {
      this.dialog = false;
      this.$router.push("/pipeline");
    },
    change() {
      this.disabled = false;

      this.editData = !this.editData;
    },
    getInvestmentsSectorsJustLabel(value) {
      const literal = {
        [2]: "Tech",
        [5]: "Pet",
        [4]: "Logistic",
        [7]: "Food",
        [6]: "Health",
        [9]: "Consumption",
      };

      return literal[value];
    },
    getCompanySizeLabel(value) {
      const literal = {
        [1]: "Startups",
        [2]: "SMEs",
        [3]: "Scale Ups",
      };

      return literal[value];
    },
    async verifyCode() {
      if (this.code.length == 4) {
        this.loading = true;
        this.isDisabled = true;
        await this.apiService
          .postRequest(
            `investor/validate-finish-register/${this.code}/${this.userId}`
          )
          .then((response) => {
            this.loading = false;
            this.isDisabled = false;
            saveUserLocalData(response.content);

            const hasLegalEntity =
              this.User.InvestorInvestIntendedPersonList.some(
                (item) =>
                  item.InvestIntendedPersonId ===
                  InvestIntendedPersonTypeEnum.PJ
              );
            const hasOffShore = this.User.InvestorInvestIntendedPersonList.some(
              (item) =>
                item.InvestIntendedPersonId ===
                InvestIntendedPersonTypeEnum.OFFSHORE
            );

            const getInvestmentSectorsLabel = this.User.SectorsList.map(
              (sector) => this.getInvestmentsSectorsJustLabel(sector)
            );
            const getInvestmentSectorsLabelToString =
              getInvestmentSectorsLabel.join(", ");

            // DISPARANDO EVENTO DO INVESTIDOR QUANDO ELE CRIA SUA CONTA COM SUCESSO
            mixpanel.track("InvestorRegister_Finish", {
              partner_id: this.User.PartnerId,
              banker_id: this.User.BankerId,
              user_name: this.User.Name,
              user_email: this.User.Email,
              user_type: "investor",
              nationality: this.User.Nationality,
              user_document_id: this.User.SocialNumber,
              phone: this.User.CelPhone,
              yearly_investment_preferences: this.User.InvestValue,
              sectors_investment_preferences: getInvestmentSectorsLabelToString,
              company_maturity_investment_preferences: this.getCompanySizeLabel(
                this.User.CompanyRevenue
              ),
              has_legal_entity: hasLegalEntity,
              has_offshore: hasOffShore,
            });

            this.dialog = true;
            this.enableLanguageMenu();
          })
          .catch((error) => {
            this.loading = false;
            this.isDisabled = false;
            this.$toast.error(this.$t("invalid_code"));
            this.code = "";
            return error;
          });
      } else {
        this.$toast.error(this.$t("invalid_code"));
      }
    },

    async resendCode() {
      this.loading = true;
      this.isDisabled = true;
      let request = {
        Id: this.userId,
        CelPhone: this.User.CelPhone,
        Email: this.User.Email,
      };
      await this.apiService
        .postRequest("user/send-another-code", request, false)
        .then((response) => {
          this.loading = false;
          this.showResendMessage = true;
          this.$toast.success(this.$t("send_code"));
          this.secondsToRetry = 15;
          this.countDownTimer();
        })
        .catch((error) => {
          this.loading = false;
          this.isDisabled = false;
          this.$toast.error(this.$t(error.body.message));
        });
    },

    createUserFromPartner() {
      this.send_code();
    },

    parseUserLanguagePref() {
      let lang = window.localStorage.getItem("lang");
      if (lang) {
        return LanguageEnum[lang];
      }

      return LanguageEnum.pt;
    },
    getUserType() {
      const userAlphaMinValue = 200000;
      return this.User.InvestValue >= userAlphaMinValue;
    },

    // Foi mais rápido  manter o método repetido em ambos os componentes
    // pq se eu colocasse na shared eu precisaria mexer com os params e nao temos mt tempo
    async send_code() {
      this.loading = true;
      this.isDisabled = true;
      await this.apiService
        .postRequest(
          "user/register/frompartner",
          {
            User: {
              ...this.User,
              Language: this.parseUserLanguagePref(),
              IsAlpha: this.getUserType(),
            },
            PartnerB2bId: this.User.PartnerId,
          },
          false
        )
        .then((response) => {
          this.userId = response;
          this.codifiedEmailAndNumber();
          this.$toast.success(this.$t("send_code"));
          this.loading = false;
          this.isDisabled = false;
          this.confirmData = false;
          this.$gtag.event("sign_up", {
            even_category: "investor_register",
            event_label: "user_investor_creation",
            value: this.User.InvestValue,
          });
        })
        .catch((error) => {
          this.isDisabled = false;
          this.$toast.error(this.$t(error.body.message));
          this.loading = false;
        });
    },
    codifiedEmailAndNumber() {
      this.findArroba = this.User.Email.indexOf("@");

      this.email_msg =
        this.User.Email.substr(0, 3) +
        "****" +
        this.User.Email.substr(this.findArroba);

      this.number_msg =
        this.User.CelPhone.substr(0, 6) +
        "****" +
        this.User.CelPhone.substr(10);
    },
    isEmailValid() {
      this.apiService
        .postRequest(
          "user/check-email",
          { User: { Email: this.User.Email } },
          false
        )
        .then((resp) => {
          this.disabled = true;
          this.disabled_next = false;
          this.editData = !this.editData;
          this.send_code();
        })
        .catch((error) => {
          this.$toast.error(this.$t("user_exists"), { timeout: 10000 });
        });
    },
  },
};
</script>
<style scoped>
.cardsHeight {
  height: 375px;
}
.disabled {
  pointer-events: none;
  color: #c4c4c4;
}
.v-text-field--rounded {
  border-radius: 4px;
}

.my-input-style >>> .v-text-field__slot input {
  color: var(--dark);
}

.button-confirm-data:focus {
  box-shadow: 0px 4px 22px var(--primary);
}

.button-confirm-data:hover {
  background-color: var(--primary-hover) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.button-confirm-data:active {
  background-color: var(--primary-focus) !important;
}

#button-finish-confirm {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 120px !important;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.15px;
  border: 1px solid var(--primary);
  border-radius: 8px;
}

.confirm-data-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 1.25;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.confirm-data-subtitle {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.confirm-data-title-form {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 18px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.step-count-title {
  margin: 0;
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 18px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 4px;
}

.button-confirm-data {
  position: absolute;
  bottom: 0;
  right: 20px;
  background: var(--primary) !important;
  color: var(--white) !important;
  border-radius: 8px;
  width: 290px !important;
  height: 54px !important;
}

.button-confirm-data > h1 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px !important;
  letter-spacing: 0.15px;
  color: var(--white);
}

.confirm-data-input {
  width: 320px;
}

.confirm-data-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  margin-bottom: 8px;
}

.validated-account-header {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 20px;
}

.validated-account-text {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: var(--dark);
}

.message-sent-email {
  font-family: "Source Sans Pro";
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.link-resend-code {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--primary) !important;
}

.dxa-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 290px !important;
  height: 54px !important;
  border-radius: 8px !important;
}

.try-again-text {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 40px;
}
</style>
