<template>
  <v-container
    id="register"
    :class="[
      step >= alphaSteps.WELCOME && step < alphaSteps.FINISH
        ? 'lessThanEight'
        : 'OnlyEight',
      gs.isMobile() ? 'mobileContainer' : 'desktopContainer',
    ]"
    class="ma-0 justify-center pa-0"
  >
    <v-slide-y-transition appear>
      <v-card
        :class="[
          step >= alphaSteps.WELCOME && step < alphaSteps.FINISH
            ? 'lessThan'
            : 'eight',
          gs.isMobile() ? 'mobile' : 'desktop',
        ]"
        light
        style="min-width: 100vw"
        elevation="0"
        class="mt-0 mb-0 fill-height"
      >
        <v-row
          v-if="step > alphaSteps.WELCOME && step < alphaSteps.FINISH"
          style="
            height: 59px;
            border-bottom: solid 1px gray;
            background-color: var(--dark);
            display: flex;
            position: relative; ;
          "
          class="ma-0"
        >
          <v-col
            :cols="step == alphaSteps.INVESTORINFO ? 1 : 2"
            v-if="
              step > alphaSteps.INVESTORINFO &&
              gs.isMobile() &&
              step !== alphaSteps.CONFIRMDATA
            "
            class="back_btn pb-0 pr-0 pt-1 ml-2 mt-2"
          >
            <v-icon x-large color="primary" @click="step -= 1"
              >mdi-arrow-left</v-icon
            >
          </v-col>
          <v-col cols="1" class="back_btn pb-0 pr-0 pt-1 ml-2">
            <v-icon
              v-if="
                step > alphaSteps.INVESTORINFO &&
                !gs.isMobile() &&
                step !== alphaSteps.CONFIRMDATA
              "
              x-large
              color="primary"
              @click="step -= 1"
              >mdi-arrow-left</v-icon
            >
          </v-col>

          <v-col cols="4" class="pl-0 mt-2" v-if="gs.isMobile()">
            <h3
              id="alpha-steps-header-mobile"
              style="color: var(--white)"
              class="my-auto ml-3"
            >
              {{
                $t("alpha_register_step_count", {
                  step: step - 1,
                  totalSteps: 6,
                })
              }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="pa-0"
            v-if="gs.isMobile()"
            style="position: absolute; bottom: 0"
          >
            <v-progress-linear
              class="pa-0 mb-0"
              color="primary"
              height="2"
              :value="((step - 1) * 100) / 6"
            ></v-progress-linear>
          </v-col>
          <v-col
            cols="2"
            style="display: flex; align-items: center"
            class="px-0 pt-1"
            v-if="!gs.isMobile()"
          >
            <img
              v-if="partnerLogo != ''"
              class="mx-auto"
              :src="gs.get_photo_path(partnerLogo)"
            />
            <img
              v-else
              style="height: 34px; max-width: 90px"
              src="../../../../../../assets/dxa-white.png"
            />
          </v-col>
        </v-row>

        <div class="pa-0 pt-0">
          <v-row
            class="ma-0"
            style="display: flex; align-items: center; flex-direction: column"
            v-if="step < alphaSteps.FINISH"
          >
            <v-col
              v-if="step == alphaSteps.INVESTORINFO && gs.isMobile()"
              cols="9"
            >
              <h2
                class="px-5 text-center"
                style="font-weight: bold; font-size: 24px; color: var(--dark)"
              >
                {{ $t(`alpha_register_4_header`) }}
              </h2>
            </v-col>

            <v-col v-if="step == alphaSteps.DATEPICKER && gs.isMobile()">
              <h1 class="px-5 text-center" style="font-weight: bold">
                {{ $t("choose_date_header") }}
              </h1>
            </v-col>
          </v-row>

          <p
            v-if="step == alphaSteps.INVESTORINFO && gs.isMobile()"
            class="px-5 text-center my-3"
            style="
              font-weight: 300;
              font-size: 18px;
              margin-left: 5%;
              margin-right: 5%;
            "
          >
            {{ $t(`alpha_register_4_subheader`) }}
          </p>
          <h4
            v-if="step == alphaSteps.DATEPICKER && gs.isMobile()"
            class="px-5 text-center my-3"
          >
            {{ $t("choose_date_subheader") }}
          </h4>
          <!-- <h5
            class="text-center my-3"
            v-if="
              $t(`alpha_register_${step}_subheader`) !=
              `alpha_register_${step}_subheader`
            "
          >
            {{ $t(`alpha_register_${step}_subheader`) }}
          </h5> -->
          <div v-if="gs.isMobile()">
            <InvestorInfo
              v-if="step < alphaSteps.CONTRACTS"
              v-show="step == alphaSteps.INVESTORINFO"
              @back="goBack"
              @next="goForward"
              :User="user"
              @getPortfolioOptions="
                (nationality) => getPortfolioOptions(nationality)
              "
            />
            <ConfirmData
              v-if="confirmDataState"
              v-show="step == alphaSteps.CONFIRMDATA"
              @back="goBack"
              @next="goForward"
              :User="user"
              :Portfolios="portfolioOptions"
            />
            <DatePicker
              v-if="!loading && confirmed_data"
              v-show="step == alphaSteps.DATEPICKER"
              @back="goBack"
              @next="register"
              :User="user"
            />
          </div>
          <div v-else>
            <InvestorInfoDesktop
              @getPortfolioOptions="
                (nationality) => getPortfolioOptions(nationality)
              "
              v-if="!gs.isMobile() && step < alphaSteps.CONTRACTS"
              v-show="step == alphaSteps.INVESTORINFO"
              @back="goBack"
              @next_step="goForward"
              :User="user"
            />
            <ConfirmDataDesktop
              v-if="confirmDataState"
              v-show="step == alphaSteps.CONFIRMDATA"
              :User="user"
              @next_step="goForward"
            />
            <DatePickerDesktop
              @next="register"
              :User="user"
              v-if="!loading && confirmed_data"
              v-show="step == alphaSteps.DATEPICKER"
            />
          </div>
          <ContractsDesktop
            v-if="step == alphaSteps.CONTRACTS"
            @next_step="goForward"
            :User="user"
            :Portfolio="
              portfolioOptions.filter(
                (x) => x.id == user.SelectedPortfolioId
              )[0]
            "
            v-show="step == alphaSteps.CONTRACTS"
            :PrivatePartner="isInvestorTech"
          />
          <Preferences
            v-if="step == alphaSteps.PREFERENCES"
            v-show="step == alphaSteps.PREFERENCES"
            :User="user"
            @next="goForward"
            :PrivatePartner="isInvestorTech"
          />
          <v-row
            justify="center"
            align="center"
            v-if="loading"
            class="my-3"
            style="min-height: 100vh"
          >
            <v-progress-circular indeterminate size="15"></v-progress-circular>
          </v-row>
        </div>
        <Finish
          v-if="step == alphaSteps.FINISH && gs.isMobile()"
          :User="user"
          :Portfolios="portfolioOptions"
        />
        <FinishDesktop
          v-if="step == alphaSteps.FINISH && !gs.isMobile()"
          :User="user"
          :Portfolios="portfolioOptions"
        />
        <SelectPortfolio
          v-if="gs.isMobile()"
          v-show="step == alphaSteps.SELECTPORTFOLIO"
          @back="goBack"
          @next="goForward"
          :User="user"
          :PortfoliosLoading="portfolioLoading"
          :PortfolioOptions="portfolioOptions"
          :PrivatePartner="isInvestorTech"
        />
        <SelelectPortfolioDesktop
          v-if="!gs.isMobile()"
          v-show="step == alphaSteps.SELECTPORTFOLIO"
          :User="user"
          :PortfolioOptions="portfolioOptions"
          @next_step="goForward"
          :PrivatePartner="isInvestorTech"
        />
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<style lang="scss">
.left-paragraphs {
  text-align: left;
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0px !important;
}
.crisp-client {
  display: none;
}
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<style scoped>
#alpha-steps-header-mobile {
  position: absolute;
  z-index: 100;
  left: 60px;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import PartnerService from "@/services/PartnerService";
import SelectPortfolio from "./alphaTabs/SelectPortfolio.vue";
//import PortfolioSize from "./alphaTabs/PortfolioSize.vue";
import InvestorInfo from "./alphaTabs/InvestorInfo.vue";
import DatePicker from "./alphaTabs/DatePicker.vue";
import ConfirmData from "./alphaTabs/ConfirmData.vue";
import Finish from "./alphaTabs/Finish.vue";
import SelelectPortfolioDesktop from "./alphaTabs/desktopSteps/SelectPortfolio.vue";
import InvestorInfoDesktop from "./alphaTabs/desktopSteps/InvestorInfo.vue";
import ConfirmDataDesktop from "./alphaTabs/desktopSteps/ConfirmDataComponent.vue";
import ContractsDesktop from "./alphaTabs/desktopSteps/ContractsComponent.vue";
import DatePickerDesktop from "./alphaTabs/desktopSteps/DatePickerComponent.vue";
import Preferences from "./alphaTabs/Preferences.vue";
import FinishDesktop from "./alphaTabs/desktopSteps/FinishComponent.vue";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";
import { alphaStepsEnum } from "@/shared/enums/AlphaStepsEnum.js";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

export default {
  name: "AlphaRegister",
  components: {
    ConfirmData,
    SelelectPortfolioDesktop,
    InvestorInfoDesktop,
    ConfirmDataDesktop,
    ContractsDesktop,
    DatePickerDesktop,
    FinishDesktop,
    SelectPortfolio,
    // PortfolioSize,
    InvestorInfo,
    DatePicker,
    Finish,
    Preferences,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    ps: new PartnerService(),
    documentTypeEnum: DocumentTypeEnum,
    valid: false,
    lazy: false,
    loading: false,
    signedContract: false,
    portfolioLoading: false,
    alphaMessage: null,
    fillForm: true,
    alert: null,
    emailExists: false,
    userValidated: false,
    checkingUser: false,
    portfolioOptions: [],
    socialNumberExists: false,
    stepTwoInitialized: true,
    stepThreeInitialized: false,
    alphaSteps: alphaStepsEnum,
    step: alphaStepsEnum.INVESTORINFO,
    stepFourInitialized: false,
    stepFiveInitialized: false,
    stepSixInitialized: false,
    stepSevenInitialized: false,
    user: {
      IsAlpha: true,
      Birth: null,
      JuridicalType: 0,
      SocietyType: 0,
      CelPhone: null,
      City: null,
      State: null,
      Name: "",
      LastName: "",
      Email: "",
      UserPassword: "",
      DocumentType: 0,
      DocumentNumber: "",
      SocialNumber: "",
      InvestValue: 0,
      PostCode: null,
      SelectedPortfolioId: null,
      CountryCode: null,
      Terms: false,
      SignedPortfolio: false,
      SignedAlphaPortfolio: false,
      PartnerId: null,
      InvestmentVehicle: "",
      SocialNumberOfInvestmentVehicle: null,
    },
    env: "",
    partnerLogo: null,
    configs: null,
    confirmDataState: false,
    confirmed_data: false,
    CurrencyEnum: CurrencyEnum,
    isInvestorTech: false,
  }),
  async created() {
    await this.validate_query();
    await this.IsInvestorTech();

    //   await this.get_portfolio_options();
    let partner_name = this.$route.query.partner;
    let partner = await this.ps.get_partner_configs(partner_name);
    let parsedPartner = JSON.parse(partner);
    this.partnerLogo = parsedPartner.CompanyLogoPath;
  },
  computed: {
    get_selected_portfolio() {
      if (this.user.SelectedPortfolio == null) {
        return null;
      }
      return this.portfolioOptions[this.user.SelectedPortfolio - 1];
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    social_number_valid() {
      return (
        (this.user.SocialNumber.replaceAll(".", "")
          .replaceAll("/", "")
          .replaceAll("-", "").length == 11 &&
          this.gs.cpf_test(
            this.user.SocialNumber.replaceAll(".", "")
              .replaceAll("/", "")
              .replaceAll("-", "")
          )) ||
        (this.user.SocialNumber.replaceAll(".", "")
          .replaceAll("/", "")
          .replaceAll("-", "").length == 14 &&
          this.cnpj_test(
            this.user.SocialNumber.replaceAll(".", "")
              .replaceAll("/", "")
              .replaceAll("-", "")
          ))
      );
    },
  },
  methods: {
    async validate_query() {
      var partnerUrl = this.$route.query.partner;
      var bankerId = this.$route.query.banker;
      // this.user.PartnerId = await this.ps.validate_partner(partnerUrl);
      if (bankerId) {
        if (!(await this.gs.validate_banker(bankerId))) {
          this.$router.replace({
            query: { partner: partnerUrl, banker: undefined },
          });
        } else {
          this.user.BankerId = bankerId;
        }
      }
    },
    async goForward(value) {
      if (value != null) {
        this.user = { ...value };
      }
      this.step += 1;
      if (this.step == this.alphaSteps.CONFIRMDATA) {
        this.confirmed_data = true;
        this.confirmDataState = true;
      }
    },

    goBack() {
      this.step -= 1;
    },
    async getPortfolioOptions(nationality) {
      this.portfolioLoading = true;
      let currency =
        nationality === "Brazil" ? CurrencyEnum.BRL : CurrencyEnum.USD;
      const partnerName = this.$route.query.partner;

      await this.apiService
        .getRequest(
          `portfolio/list-all?currency=${currency}&partnerName=${partnerName}`
        )
        .then((result) => {
          this.portfolioOptions = result.content;
          if (this.portfolioOptions.length > 0) {
            this.user.SelectedPortfolioId = this.portfolioOptions[1].id;
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.portfolioLoading = false;
    },
    async register(eventUri) {
      this.user.DocumentType = this.documentTypeEnum.socialNumber;
      this.error = null;
      this.loading = true;
      this.user.CalendlyEventUri = eventUri;
      if (
        this.user.CountryCode.callingCodes[0] &&
        this.user.CelPhone.substring(
          0,
          this.user.CountryCode.callingCodes[0].length
        ) != this.user.CountryCode.callingCodes[0]
      ) {
        this.user.CelPhone = `+${this.user.CountryCode.callingCodes[0]}${this.user.CelPhone}`;
      }
    },
    async IsInvestorTech() {
      const partnerName = this.$route.query.partner;

      await this.apiService
        .getRequest(`partner/get/${partnerName}`)
        .then((response) => {
          const parsedResp = JSON.parse(response);
          this.user.PartnerId = parsedResp.Id;
          this.isInvestorTech = parsedResp.IsTech;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
  },
};
</script>
<style scoped>
.lessThanEight {
  margin-top: 0px;
  height: 100%;
}
.OnlyEight {
  margin-top: 0px;
}
.lessThan {
  background-color: var(--white);
  min-height: 725px;
}
.eight {
  background-color: rgba(255, 255, 255, 0);
}
.mobile {
  width: 700px;
  background-color: var(--white);
  display: block;
  line-height: normal;
}
.desktop {
  background-color: var(--white);
  display: block;
  line-height: normal;
}
.desktopContainer {
  min-width: 100vw;
}
</style>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
