<template>
  <div class="main-content">
    <div class="content">
      <div class="header-container">
        <span class="header-title">{{ $t("portfolio_preferences") }}</span>
        <span class="header-subtitle">{{ $t("preferences_subtitle") }}</span>
      </div>
      <div class="step-container">
        <span class="step">{{
          $t("alpha_register_step_count", { step: 3, totalSteps: 6 })
        }}</span>
      </div>
      <div class="progress" />
      <UserPreferences
        :PrivatePartner="PrivatePartner"
        :editable="true"
        @btnConfirm="nextStep"
        btnText="alpha_register_create_portfolio"
        :User="User"
        :userType="userTypeEnum.Investor"
      />
    </div>
  </div>
</template>

<script>
import UserPreferences from "@/components/dxa/userPreferences/UserPreferences.vue";
import { UserTypeEnum } from "@/shared/enums/UserType";
export default {
  name: "Preferences",
  props: {
    User: Object,
    PrivatePartner: { type: Boolean, default: false },
  },
  data() {
    return {
      userTypeEnum: UserTypeEnum,
    };
  },
  components: {
    UserPreferences,
  },
  methods: {
    nextStep() {
      this.$gtag.event("preferences", {
        event_category: "investor_register",
        event_label: "user_preferences",
        value: this.User.InvestValue,
      });

      this.$emit("next");
    },
  },
};
</script>

<style scoped>
.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.header-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
}

.header-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.header-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.step-container {
  margin-top: 35px;
}

.step {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    flex-direction: column;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .header-container {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .content {
    width: 100%;
    flex-direction: column;
  }

  .header-subtitle {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #9c9c9c;
  }

  .header-title {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--dark);
    margin-bottom: 8px;
  }

  .step-container {
    display: none;
  }
}
</style>
