<template>
  <div class="select-porfolio-bg" style="margin-bottom: 0px">
    <div class="select-portfolio-grid">
      <div style="margin-right: 95px">
        <h2 class="select-portfolio-title" v-if="PrivatePartner">
          {{ $t(`alpha_register_2_header_tech`) }}
        </h2>
        <h2 class="select-portfolio-title" v-else>
          {{ $t(`alpha_register_2_header`) }}
        </h2>
        <h3 class="select-portfolio-subtitle">
          {{ $t(`alpha_register_2_subheader`) }}
        </h3>
        <h3 class="step-count-title">
          {{ $t("step_count", { current: 2, last: 6 }) }}
        </h3>
        <v-progress-linear
          color="primary"
          style="width: 200px"
          rounded
          height="6"
          value="200"
        ></v-progress-linear>
        <div class="select-portfolio-content">
          <ul class="select-portfolio-content-list">
            <div>
              <li>
                <v-icon small color="primary">mdi-check</v-icon>
                <p class="select-portfolio-content-list-item">
                  {{ $t("alpha_register_suggestions_allocations") }}
                </p>
              </li>
              <li v-if="PrivatePartner">
                <v-icon small color="primary">mdi-check</v-icon>
                <p class="select-portfolio-content-list-item">
                  {{ $t("regulated_manager_cvm") }}
                </p>
              </li>
              <li v-if="PrivatePartner">
                <v-icon small color="primary">mdi-check</v-icon>
                <p class="select-portfolio-content-list-item">
                  {{ $t("exclusive_opportunities") }}
                </p>
              </li>
              <li v-if="!PrivatePartner">
                <v-icon small color="primary">mdi-check</v-icon>
                <p class="select-portfolio-content-list-item">
                  {{ $t("alpha_register_plan_investment") }}
                </p>
              </li>
              <li v-if="!PrivatePartner">
                <v-icon small color="primary">mdi-check</v-icon>
                <p class="select-portfolio-content-list-item">
                  {{ $t("alpha_register_exclusive_management") }}
                </p>
              </li>
            </div>
            <div>
              <li v-if="!PrivatePartner">
                <v-icon small color="primary">mdi-check</v-icon>
                <p class="select-portfolio-content-list-item">
                  {{ $t("alpha_register_active_governance") }}
                </p>
              </li>
              <li v-if="!PrivatePartner">
                <v-icon small color="primary">mdi-check</v-icon>
                <p class="select-portfolio-content-list-item">
                  {{ $t("alpha_register_start_investment") }}
                </p>
              </li>
            </div>
          </ul>
          <div class="items-center">
            <DxaButton
              data-test="Alpha:SelectPortfolio:Next"
              :title="$t('alpha_register_create_portfolio')"
              class="mt-10 button-next-step"
              @function="nextStep"
            ></DxaButton>
          </div>
        </div>
      </div>
    </div>
    <v-img
      class="dxa-background-x"
      max-width="45%"
      src="../../../../../../../../assets/dxa-background-welcome-page3.png"
    ></v-img>
  </div>
</template>
<script>
import ValidationServices from "../../../../../../../../services/ValidationService";
import ApiServices from "@/services/ApiService";
import DxaButton from "@/components/dxa/Button/DxaButton.vue";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

export default {
  components: {
    DxaButton,
  },
  data() {
    return {
      vs: new ValidationServices(),
      apiService: new ApiServices(),
      slider: 0,
      step: 0,
      model: 0,
      CurrencyEnum: CurrencyEnum,
      dataWallets: [],
    };
  },
  props: {
    PortfolioOptions: Array,
    PrivatePartner: Boolean,
  },
  watch: {},
  methods: {
    nextStep() {
      this.$gtag.event("select_portfolio", {
        event_category: "investor_register",
        event_label: "select_portfolio",
        value: this.step,
      });

      this.$emit("next_step");
    },
  },
};
</script>
<style scoped>
.select-porfolio-bg {
  width: 100%;
  min-height: 100vh;
  background-color: var(--dark);
  z-index: -1;
  padding: 0 20px;
}

.select-portfolio-title {
  font-family: "Source Sans Pro";
  font-size: 35px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.15px;
  color: var(--white);
  margin-bottom: 0.5rem;
}

.select-portfolio-subtitle {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-bottom: 34px;
}

.dxa-background-x {
  position: absolute;
  bottom: 25px;
  left: 25px;
  z-index: 1;
}

.select-portfolio-content {
  background-color: var(--white);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 60px;
  position: relative;
  z-index: 2;
  margin-bottom: 100px;
}

.select-portfolio-content-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  margin-bottom: 40px;
}

.select-portfolio-content-list > div li + li {
  margin-top: 10px;
}

.select-portfolio-content-list li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.select-portfolio-content-list li > p {
  margin: 0;
  padding: 0;
}

.select-portfolio-content-list-item {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.step-count-title {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 18px;
  line-height: 1.27;
  letter-spacing: 0.15px;
  color: var(--white);
  margin-bottom: 0.5rem;
}

.btn-move-slide {
  cursor: pointer;
  display: block;
  position: absolute;
  margin-right: 10px;
  top: 45%;
}

.btn-prev-slide {
  left: -80px;
}

.btn-next-slide {
  right: -80px;
}

.cards-center {
  display: grid;
  place-items: center;
}

.planned-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin: 0;
}

.planned-value {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin: 0;
}

.planned-box {
  position: absolute;
  bottom: -1px;
  right: -1px;
  border: 0;
  margin-bottom: 0px;
  width: 136px;
  background-color: #fff;
  border-radius: 12px 0 !important;
  padding: 9px 30px;
}

.select-portfolio-grid {
  display: grid;
  place-items: center;
  padding-top: 40px;
}

.items-center {
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss" scoped>
.v-input--checkbox::v-deep {
  .v-label {
    color: var(--dark);
    font-size: 16px;
  }
}
</style>
