<template>
  <div class="pa-5">
    <v-form
      @submit.stop.prevent="verifyCode"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <div
        v-if="confirmData == false"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div>
          <v-dialog width="300" v-model="dialog"
            ><v-card
              style="
                background-color: var(--white);
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div style="display: flex; justify-content: end; float: right">
                <v-btn
                  @click="goToPipeline()"
                  class="pa-5 pb-0"
                  width="50px"
                  x-small
                  elevation="0"
                  style="background-color: var(--white); display: flex"
                  ><v-icon color="black">mdi-close</v-icon></v-btn
                >
              </div>
              <v-icon
                x-large
                color="primary"
                class="pb-2 pt-0"
                style="border-radius: 20px"
                >mdi-check-circle</v-icon
              >
              <span
                class="py-0 px-4 validated-account-header"
                style="
                  display: flex;
                  flex-direction: column;
                  text-align: center;
                "
                >{{ $t("validated_account_header") }}</span
              >
              <p
                class="px-5 validated-account-text"
                style="
                  display: flex;
                  flex-direction: column;
                  text-align: center;
                "
              >
                {{ $t("validated_account_text") }}
              </p>
              <v-btn
                data-test="Alpha:ConfirmData:BtnContinue"
                id="button-finish-confirm"
                class="px-5 mx-5 my-5"
                outlined
                @click="goToPipeline()"
                color="primary"
              >
                {{ $t("continue") }}
              </v-btn>
            </v-card>
          </v-dialog>
          <v-row class="ma-0">
            <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
              <strong>{{ $t("confirm_data_alpha") }}</strong>
            </p>
            <span
              class="confirm-data-subtitle"
              style="text-align: center"
              v-html="
                $t('message_sent_email_and_cellphone', {
                  email: email_msg,
                  cellphone: number_msg,
                })
              "
            >
            </span>
          </v-row>

          <v-row class="ma-0 mt-12">
            <v-col cols="12" class="pa-0">
              <span class="confirm-data-label">{{ $t("pin") }}</span>
              <v-text-field
                background-color="white"
                data-test="Alpha:ConfirmData:ConfirmPin"
                hide-details
                dense
                v-mask="['####']"
                :rules="[required]"
                outlined
                v-model="code"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              class="pt-0 pr-0 pl-0"
              style="display: flex; text-decoration: none"
              ><a
                class="link-resend-code"
                :class="[isDisabled ? 'disabled' : '']"
                @click="resendCode"
                >{{ $t("re_send_code") }}</a
              >
            </v-col>
            <v-col
              style="display: flex; justify-content: end"
              class="px-0 pt-1"
            >
              <a v-if="showResendMessage" class="try-again-text">{{
                $t("try_again_in", { countDown: secondsToRetry })
              }}</a>
            </v-col>
          </v-row>
          <v-row
            class="mt-1"
            justify="center"
            style="max-width: 275px; margin: 0 auto"
          >
          </v-row>
        </div>
        <div style="margin-top: 150px; width: 100%">
          <v-btn
            data-test="Alpha:ConfirmData:ConfirmDataAlpha"
            class="primary ma-0 button-confirm-data"
            type="submit"
            :loading="loading"
            ><h1>{{ $t("confirm") }}</h1></v-btn
          >
        </div>
      </div>
      <div
        v-if="confirmData"
        style="display: flex; flex-direction: column; justify-content: center"
      >
        <h1 class="mb-4 confirm-data-title" style="text-align: center">
          {{ $t("confirm_data_alpha") }}
        </h1>
        <p class="confirm-data-subtitle" style="text-align: center">
          {{ $t("confirm_data_new_text") }}
        </p>
        <v-row class="ma-0">
          <v-col cols="12">
            <span class="confirm-data-label">{{ $t("single_celphone") }}</span>
            <v-text-field
              @keydown.space.prevent
              background-color="white"
              dense
              outlined
              v-mask="
                User.CountryCode && User.CountryCode.callingCodes[0] == '55'
                  ? '+55 (##) #####-####'
                  : `+${User.CountryCode.callingCodes[0]} ##########`
              "
              :rules="[required]"
              hide-details
              v-model="User.CelPhone"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <span class="confirm-data-label">{{ $t("email") }}</span>

            <v-text-field
              @keydown.space.prevent
              color="primary"
              background-color="white"
              dense
              outlined
              :rules="[required]"
              v-model="User.Email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <v-btn
              data-test="Alpha:ConfirmData:NextStep"
              class="ma-0 primary button-confirm-data"
              style="border-radius: 15px"
              :class="loading ? 'disabled' : ''"
              :loading="loading"
              @click="createUserFromPartner()"
              ><h1>{{ this.$t("confirm") }}</h1></v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-form>

    <v-alert class="ma-2" v-if="alert" type="error">
      {{ alert }}
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.disabled {
  pointer-events: none !important;
  color: grey !important;
}
</style>

<style scoped>
.button-confirm-data:focus {
  box-shadow: 0px 4px 22px var(--primary);
}

.button-confirm-data:hover {
  background-color: var(--primary-hover) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.button-confirm-data:active {
  background-color: var(--primary-focus) !important;
}

#button-finish-confirm {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 120px !important;
  font-family: "Source Sans Pro";
  border: 1px solid var(--primary);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.15px;
}

.confirm-data-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.confirm-data-subtitle {
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.confirm-data-label {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.button-confirm-data {
  width: 320px;
  border-radius: 8px !important;
  height: 44px;
}

.button-confirm-data h1 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px !important;
  letter-spacing: 0.15px;
}

.link-resend-code {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.15px;
  color: var(--primary) !important;
  margin-top: 48px;
}

.try-again-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark);
  margin-top: 45px;
  padding-bottom: 0;
}

.validated-account-header {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 20px;
}

.validated-account-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--dark);
}

@media only screen and (max-width: 600px) {
  .button-confirm-data {
    width: 100% !important;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import { saveUserLocalData } from "@/shared/helpers/authHelpers";
import { LanguageEnum } from "@/shared/enums/LanguageEnum";
import mixpanel from "mixpanel-browser";
import { InvestIntendedPersonTypeEnum } from "@/shared/enums/InvestIntendedPersonTypeEnum";

export default {
  name: "ConfirmData",
  components: {},
  data: () => ({
    disabledEdition: true,
    editData: false,
    confirmData: true,
    apiService: new ApiService(),
    disabled: true,
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
    email_msg: null,
    findArroba: null,
    number_msg: null,
    code: null,

    dialog: false,
    isDisabled: true,
    secondsToRetry: 15,
    showResendMessage: false,
    userId: null,
    InvestIntendedPersonTypeEnum,
  }),
  async created() {
    this.codifiedEmailAndNumber();
    this.disabledLanguageMenuEvent();
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.checkForm();
      }
    },
  },
  props: {
    User: Object,
    Step: Number,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    disabledLanguageMenuEvent() {
      const event = new Event("disableLanguageMenu");
      window.dispatchEvent(event);
    },
    createUserFromPartner() {
      this.send_code();
    },
    countDownTimer() {
      setInterval(() => {
        this.secondsToRetry -= 1;
        if (this.secondsToRetry === 0) {
          clearInterval();
          this.isDisabled = false;
          this.showResendMessage = false;
        }
      }, 1000);
    },
    goToPipeline() {
      this.dialog = false;
      this.$router.push("/pipeline");
    },
    getInvestmentsSectorsJustLabel(value) {
      const literal = {
        [2]: "Tech",
        [5]: "Pet",
        [4]: "Logistic",
        [7]: "Food",
        [6]: "Health",
        [9]: "Consumption",
      };

      return literal[value];
    },
    getCompanySizeLabel(value) {
      const literal = {
        [1]: "Startups",
        [2]: "SMEs",
        [3]: "Scale Ups",
      };

      return literal[value];
    },
    async verifyCode() {
      if (this.code.length == 4) {
        this.loading = true;
        this.isDisabled = true;
        await this.apiService
          .postRequest(
            `investor/validate-finish-register/${this.code}/${this.userId}`
          )
          .then((response) => {
            saveUserLocalData(response.content);
            this.isDisabled = false;
            this.loading = false;

            const hasLegalEntity =
              this.User.InvestorInvestIntendedPersonList.some(
                (item) =>
                  item.InvestIntendedPersonId ===
                  InvestIntendedPersonTypeEnum.PJ
              );
            const hasOffShore = this.User.InvestorInvestIntendedPersonList.some(
              (item) =>
                item.InvestIntendedPersonId ===
                InvestIntendedPersonTypeEnum.OFFSHORE
            );

            const getInvestmentSectorsLabel = this.User.SectorsList.map(
              (sector) => this.getInvestmentsSectorsJustLabel(sector)
            );
            const getInvestmentSectorsLabelToString =
              getInvestmentSectorsLabel.join(", ");

            // DISPARANDO EVENTO DO INVESTIDOR QUANDO ELE CRIA SUA CONTA COM SUCESSO
            mixpanel.track("InvestorRegister_Finish", {
              partner_id: this.User.PartnerId,
              banker_id: this.User.BankerId,
              user_name: this.User.Name,
              user_email: this.User.Email,
              user_type: "investor",
              nationality: this.User.Nationality,
              user_document_id: this.User.SocialNumber,
              phone: this.User.CelPhone,
              yearly_investment_preferences: this.User.InvestValue,
              sectors_investment_preferences: getInvestmentSectorsLabelToString,
              company_maturity_investment_preferences: this.getCompanySizeLabel(
                this.User.CompanyRevenue
              ),
              has_legal_entity: hasLegalEntity,
              has_offshore: hasOffShore,
            });

            this.dialog = true;
            this.enableLanguageMenu();
          })
          .catch((error) => {
            this.loading = false;
            this.isDisabled = false;
            this.$toast.error(this.$t("invalid_code"));
            this.code = "";
            return error;
          });
      }
    },
    async resendCode() {
      this.loading = true;
      this.isDisabled = true;
      let request = {
        Id: this.userId,
        CelPhone: this.User.CelPhone,
        Email: this.User.Email,
      };
      await this.apiService
        .postRequest("user/send-another-code", request, false)
        .then((response) => {
          this.loading = false;
          this.showResendMessage = true;
          this.$toast.success(this.$t("send_code"));
          this.secondsToRetry = 15;
          this.countDownTimer();
        })
        .catch((error) => {
          this.loading = false;
          this.isDisabled = false;
          this.$toast.error(this.$t(error.body.message));
        });
    },
    parseUserLanguagePref() {
      let lang = window.localStorage.getItem("lang");
      if (lang) {
        return LanguageEnum[lang];
      }

      return LanguageEnum.pt;
    },
    enableLanguageMenu() {
      const event = new Event("enableLanguageMenu");
      window.dispatchEvent(event);
    },
    getUserType() {
      const userAlphaMinValue = 200000;
      return this.User.InvestValue >= userAlphaMinValue;
    },
    async send_code() {
      this.loading = true;

      await this.apiService
        .postRequest(
          "user/register/frompartner",
          {
            User: {
              ...this.User,
              Language: this.parseUserLanguagePref(),
              IsAlpha: this.getUserType(),
            },
            PartnerB2bId: this.User.PartnerId,
          },
          false
        )
        .then((response) => {
          this.userId = response;
          this.codifiedEmailAndNumber();
          this.$toast.success(this.$t("send_code"));
          this.loading = false;
          this.isDisabled = false;
          this.confirmData = false;
          this.$gtag.event("sign_up", {
            event_category: "investor_register",
            event_label: "user_investor_creation",
            value: this.User.InvestValue,
          });
        })
        .catch((error) => {
          this.isDisabled = false;
          this.$toast.error(this.$t(error.body.message));
          this.loading = false;
        });
    },
    isEmailValid() {
      this.apiService
        .postRequest(
          "user/check-email",
          { User: { Email: this.User.Email } },
          false
        )
        .then((resp) => {
          this.disabled = true;
          this.disabled_next = false;
          this.editData = !this.editData;
          this.send_code();
        })
        .catch((error) => {
          this.$toast.error(this.$t("user_exists"), { timeout: 10000 });
        });
    },
    change() {
      this.disabled = false;

      this.editData = !this.editData;
    },
    codifiedEmailAndNumber() {
      this.findArroba = this.User.Email.indexOf("@");

      this.email_msg =
        this.User.Email.substr(0, 3) +
        "****" +
        this.User.Email.substr(this.findArroba);
      this.number_msg =
        this.User.CelPhone.substr(0, 10) +
        "****" +
        this.User.CelPhone.substr(14);
    },

    checkForm: async function () {
      this.$emit("register");
      var result = this.$refs.form.validate();
      if (result) {
        await this.apiService
          .postRequest(
            "user/check-email",
            { User: { Email: this.User.Email } },
            false
          )
          .then((resp) => {
            this.$emit("next");
          })
          .catch((error) => {
            this.$toast.error(this.$t("an_error_ocurred"));

            this.alert = this.$t("user_exists");
          });
      }
    },
  },
};
</script>
