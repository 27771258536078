<template>
  <v-form
    lazy-validation
    v-model="valid"
    ref="form"
    class="mt-3 pa-5"
    style=""
    :class="!isMobile ? 'DesktopCard' : ''"
  >
    <div v-if="$route.name != 'AlphaRegisterClientDXA'">
      <span class="field-header" style="color: var(--dark)">{{
        $t("nationality")
      }}</span>
      <v-combobox
        color="black"
        class="mb-4 my-combobox-style"
        dense
        filled
        rounded
        :rules="[required]"
        :items="countries"
        :disabled="User.JuridicalType == JuridicalTypeEnum.LegalPerson"
        item-text="name"
        return-object
        @change="(e) => nationality_code_changed(e)"
        v-model="nationalitySelected"
        data-test="Alpha:InvestorInfo:Nationality"
      >
        <template slot="selection" slot-scope="data">
          <img :src="data.item.flag" style="width: 20px; height: auto" alt="" />
          <span class="mx-2">{{ data.item.name }}</span>
        </template>
        <template slot="item" slot-scope="data">
          <img :src="data.item.flag" style="width: 20px; height: auto" alt="" />
          <span class="mx-2">{{ data.item.name }}</span>
        </template></v-combobox
      >
      <span class="field-header" style="color: var(--dark)">{{
        $t("document_type")
      }}</span>
      <v-radio-group v-model="User.DocumentType" style="margin-top: 4px" row>
        <v-radio
          style="display: flex; align-itens: center"
          v-for="docType in docsType"
          :key="docType.value"
          :value="docType.value"
          :data-test="`Alpha:InvestorInfo:documentType:${docType.text}`"
        >
          <template v-slot:label>
            <span class="document-type-text">{{
              translateDocsType(docType)
            }}</span>
          </template>
        </v-radio>
      </v-radio-group>
      <label class="field-header" style="color: var(--dark)">{{
        $t("document_number")
      }}</label>
      <v-text-field
        data-test="Alpha:InvestorInfo:DocumentNumber"
        dense
        class="my-input-style"
        rounded
        :placeholder="
          User.DocumentType == documentTypeEnum.cpf ? '000.000.000-00' : ''
        "
        :class="{ 'invalid-field': !User.SocialNumber || socialNumberExists }"
        v-model="User.SocialNumber"
        type="tel"
        :rules="[
          required,
          (v) =>
            (User.DocumentType == documentTypeEnum.cpf
              ? vs.social_number_valid(User.SocialNumber)
              : User.SocialNumber.length >= 7) || $t('format_invalid'),
        ]"
        :disabled="User.DocumentType == documentTypeEnum.select ? true : false"
        filled
        v-mask="
          User.DocumentType == documentTypeEnum.cpf ? '###.###.###-##' : ''
        "
        @change="check_social_number()"
      />
      <p
        style="color: red; font-size: 11px"
        class="mb-4"
        v-if="socialNumberExists"
      >
        {{
          User.DocumentType == documentTypeEnum.cpf
            ? $tc("social_number_exists", 1)
            : $tc("social_number_exists", 2)
        }}
      </p>

      <p class="field-header mb-0" style="color: var(--dark)">
        {{ $t("name") }}
      </p>
      <v-text-field
        data-test="Alpha:InvestorInfo:Name"
        v-model="User.Name"
        filled
        rounded
        :rules="[(v) => (!!v && User.Name != null) || required()]"
        dense
        class="mb-4 my-input-style"
        :placeholder="$t('type_your_name')"
      ></v-text-field>
      <p class="field-header mb-0" style="color: var(--dark)">
        {{ $t("last_name") }}
      </p>
      <v-text-field
        :placeholder="$t('type_your_lastname')"
        data-test="Alpha:InvestorInfo:LastName"
        v-model="User.LastName"
        filled
        rounded
        :rules="[(v) => (!!v && User.LastName != null) || required()]"
        dense
        class="mb-4 my-input-style"
      ></v-text-field>
      <p class="field-header mb-0" style="color: var(--dark)">
        {{
          $t(
            User.JuridicalType == JuridicalTypeEnum.NaturalPerson
              ? $t("birth_date")
              : $t("establishment_date")
          )
        }}
      </p>
      <v-text-field
        data-test="Alpha:InvestorInfo:BirthDate"
        filled
        rounded
        dense
        :class="{
          'invalid-field': !User.Birth || !isDateValid || !isDateAboveLegal,
        }"
        v-model="date"
        :rules="[(v) => (!!v && v.length == 10) || required()]"
        placeholder="DD/MM/YYYY"
        v-mask="['##/##/####']"
        @blur="
          User.Birth = parseDate(date);
          check_date();
        "
        type="tel"
        class="my-input-style"
      />
      <p
        style="color: red; font-size: 11px"
        class="mb-4 mt-n4 ml-2"
        v-if="date == '' && fieldsRequired == true"
      >
        {{ $t("required") }}
      </p>
      <p
        style="color: red; font-size: 11px"
        class="mb-4 mt-n3 ml-3"
        v-if="!isDateValid"
      >
        {{ $t("valid_date") }}
      </p>
      <p
        style="color: red; font-size: 11px"
        class="mb-4 mt-n4 ml-2"
        v-else-if="!isDateAboveLegal"
      >
        {{ $t("min_18") }}
      </p>

      <p
        style="color: var(--dark); font-size: 11px"
        class="ma-0"
        v-if="
          validatingSocialNumber && User.DocumentType == documentTypeEnum.cpf
        "
      >
        {{ $t("validating") }}
      </p>
      <p
        style="color: red; font-size: 11px"
        class="ma-0"
        v-else-if="
          socialNumberFormatInvalid && User.DocumentType == documentTypeEnum.cpf
        "
      >
        {{ $t("format_invalid") }}
      </p>

      <p class="field-header mb-0" style="color: var(--dark)">
        {{ $t("email") }}
      </p>
      <v-text-field
        :placeholder="$t('email_placeholder')"
        data-test="Alpha:InvestorInfo:Email"
        v-model="User.Email"
        filled
        rounded
        @keydown.space.prevent
        dense
        :hide-details="validatingEmail"
        @change="check_email"
        :rules="[
          required,
          email_valid,
          !emailFormatInvalid || $t('format_invalid'),
          !emailExists || $t('user_exists'),
        ]"
        :class="validatingEmail ? 'mb-0' : 'mb-4'"
        class="my-input-style"
      ></v-text-field>
      <p v-if="validatingEmail">{{ $t("validating") }}</p>
    </div>
    <v-row no-gutters>
      <v-col cols="4" md="4" class="my-0 py-0 pr-3">
        <span class="field-header" style="color: var(--dark)">{{
          $t("country_code")
        }}</span>
      </v-col>
      <v-col cols="8" md="8" class="my-0 py-0">
        <span class="field-header" style="color: var(--dark)">{{
          $t("celphone")
        }}</span>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-4">
      <v-col cols="4" md="4" class="my-0 py-0 pr-3">
        <v-autocomplete
          color="black"
          dense=""
          filled
          rounded
          :rules="[(v) => (!!v && countryCode != null) || required()]"
          :items="countries"
          item-text="name"
          return-object
          @change="country_code_changed"
          v-model="countryCode"
          data-test="Alpha:InvestorInfo:CountryCode"
          class="my-combobox-style"
        >
          <template slot="selection" slot-scope="data">
            <img
              :src="data.item.flag"
              style="width: 20px; height: auto"
              alt=""
            />
            <span class="mx-2">{{ data.item.callingCodes[0] }}</span>
          </template>
          <template slot="item" slot-scope="data">
            <img
              :src="data.item.flag"
              style="width: 20px; height: auto"
              alt=""
            />
            <span class="mx-2">{{ data.item.name }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="8" md="8" class="my-0 py-0">
        <v-text-field
          class="my-input-style"
          data-test="Alpha:InvestorInfo:Celphone"
          autocomplete="off"
          dense
          rounded
          style="border-radius: 4px"
          @keydown.space.prevent
          :class="{ 'invalid-field': !User.CelPhone }"
          v-model="User.CelPhone"
          :rules="[
            (v) =>
              (!!v &&
                ((countryCode &&
                  countryCode.callingCodes[0] != '55' &&
                  User.CelPhone.length >= 8) ||
                  (countryCode &&
                    countryCode.callingCodes[0] == '55' &&
                    User.CelPhone.length >= 14))) ||
              required(),
          ]"
          filled
          v-mask="
            countryCode && countryCode.callingCodes[0] == '55'
              ? '(##) #####-####'
              : ''
          "
          type="tel"
        />
      </v-col>
      <v-col class="mt-3" cols="12">
        <span class="field-header" style="color: var(--dark)">{{
          $t("password")
        }}</span>
        <v-text-field
          data-test="Alpha:InvestorInfo:Password"
          @keyup="verifyRequirements"
          @keydown.space.prevent
          dense
          rounded
          ref="password"
          :rules="[(v) => (!!v && verifyAllPasswordRequirements) || required()]"
          v-model="User.UserPassword"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1"
          filled
          class="mb-4 my-input-style"
          autocomplete="off"
        ></v-text-field>
      </v-col>
      <v-col cols="12"
        ><span class="field-header" style="color: var(--dark)">{{
          $t("password_confirmation")
        }}</span>

        <v-text-field
          data-test="Alpha:InvestorInfo:PasswordConfirmation"
          @keydown.space.prevent
          v-model="passwordConfirmation"
          :rules="[passwordConfirmation == User.UserPassword || required()]"
          dense
          rounded
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1"
          filled
          class="ma-0 my-input-style"
          autocomplete="off"
        >
        </v-text-field>
        <p
          style="color: red; font-size: 11px"
          class="ma-0"
          v-if="
            passwordConfirmation != User.UserPassword &&
            passwordConfirmation != null
          "
        >
          {{ $t("pswds_dnt_match") }}
        </p>
      </v-col>
      <v-col cols="12" class="mt-5">
        <ValidateStrengthPassword :userPassword="User.UserPassword" />
      </v-col>
      <v-col class="mt-1 mb-10" cols="12">
        <PasswordChecklist
          :password="User.UserPassword"
          ref="passwordChecklist"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-0">
      <v-btn
        @click="checkForm"
        color="primary"
        data-test="Alpha:InvestorInfo:Submit"
        class="next-button mx-auto my-1 mb-3"
        style="border-radius: 15px; width: 333px; height: 50px"
        ><span>{{ $t("continue") }}</span></v-btn
      >
    </v-row>

    <v-dialog persistent width="300" v-model="socialNumberDialog"
      ><v-card
        class="dialog-bg"
        style="border-radius: 10px; background-color: var(--white) !important"
      >
        <v-row justify="end" align="center" class="pr-6">
          <v-icon @click="socialNumberDialog = false" color="black"
            >mdi-close</v-icon
          >
        </v-row>
        <div class="icon-alert">
          <img src="@/assets/alert-icon.svg" alt="alert" />
        </div>
        <h2 class="pt-3 px-4 pb-3 text-center" style="color: var(--dark)">
          {{ $t("social_number_already_exists") }}
        </h2>
        <p
          class="px-4 text-center"
          style="
            max-width: 400px;
            letter-spacing: 0.25px;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Source Sans Pro';
            color: var(--dark);
            margin-left: auto;
            margin-right: auto;
          "
        >
          {{ $t("if_social_number_is_yours") }}
        </p>
        <div class="alert-create-box">
          <v-btn
            outlined
            style="
              border-style: groove;
              border-color: var(--primary);
              background-color: var(--white);
              color: var(--primary);
              font-size: 16px;
              border-radius: 8px;
            "
            height="35"
            width="120"
            @click="socialNumberDialog = false"
            class="mb-5"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            outlined
            style="
              border-radius: 8px;
              background-color: var(--primary);
              color: var(--white);
              border: none;
              font-size: 16px;
            "
            height="35"
            width="120"
            @click="goToLogin"
            class="mb-5"
            >{{ $t("login_txt") }}</v-btn
          >
        </div>
      </v-card></v-dialog
    >
  </v-form>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
.DesktopCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.disabled {
  background-color: #c4c4c4;
}
.v-text-field--rounded {
  border-radius: 4px;
}
.field-header {
  font-size: 16px;
  color: #9c9c9c;
}
.requirements {
  font-size: 14px;
  font-weight: 300;
  color: var(--dark);
}
.next-button {
  color: var(--white);
  background: var(--primary) !important;
  border-radius: 15px;
  font-size: 24px;
  box-shadow: none;
  font-weight: bold;
}
</style>

<style scoped>
.document-type-text {
  font-size: 16px !important;
  font-family: "Source Sans Pro";
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.alert-create-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
}

.icon-alert {
  display: grid;
  place-items: center;
}

.dialog-bg {
  padding-top: 20px;
}

.my-input-style >>> .v-text-field__slot input {
  color: var(--dark);
}

.my-combobox-style >>> .v-select__slot span {
  color: var(--dark);
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationServices from "../../../../../../../services/ValidationService";
import countriesJson from "@/utils/countries.json";
import moment from "moment";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";
import PasswordChecklist from "@/components/dxa/PasswordChecklist/PasswordChecklist.vue";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";
import mixpanel from "mixpanel-browser";
import ValidateStrengthPassword from "@/components/dxa/Password/ValidateStrengthPassword";
import { getAlphaRegisterUrlLang } from "@/shared/helpers/alphaRegisterHelpers";


export default {
  name: "InvestorInfo",
  components: { PasswordChecklist, ValidateStrengthPassword },
  data: () => ({
    documentTypeEnum: DocumentTypeEnum,
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationServices(),
    valid: true,
    validatingEmail: false,
    emailExists: false,
    emailFormatInvalid: false,
    lazy: false,
    isDateValid: true,
    isDateAboveLegal: true,
    fieldsRequired: false,
    socialNumberExists: false,
    socialNumberFormatInvalid: false,
    validatingSocialNumber: false,
    date: null,
    countries: countriesJson,
    documentNumber: "",
    documentType: 0,
    error: null,
    passwordConfirmation: null,
    socialNumberDialog: false,
    show1: false,
    isMobile: false,
    clickNext: false,
    countryCode: null,
    docsType: [{ text: "CPF", value: 1 }],
    nationalitySelected: null,
    JuridicalTypeEnum,
    getAlphaRegisterUrlLang
  }),

  async created() {
    this.isMobile = this.gs.isMobile();
    const pagePath = this.$route.fullPath;
    const pagePathLang = this.getAlphaRegisterUrlLang(pagePath);

    if (pagePathLang) {
      this.$i18n.locale = pagePathLang;
      this.$store.commit("SET_LANGUAGE", pagePathLang);
    } else {
      this.$i18n.locale = "pt";
      this.$store.commit("SET_LANGUAGE", "pt");
    }

    if (this.User.Nationality == null) {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == "Brazil"
      )[0];
      this.User.Nationality = "Brazil";
    } else {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == this.User.Nationality
      )[0];
    }
    if (this.User.Birth) {
      var birth = moment(this.User.Birth);
      if (birth.format("YYYY") != "0001") {
        this.date = birth.format("DD/MM/YYYY");
      }
    }
    if (this.countryCode == null || this.countryCode == "") {
      this.countryCode = this.countries.filter((x) => x.name == "Brazil")[0];

      this.User.CountryCode = this.countryCode;
    }
    if (this.User.DocumentType == this.documentTypeEnum.select) {
      this.User.DocumentType = this.documentTypeEnum.cpf;
    }
    this.checkInvestorFormData();

    if (this.User.Nationality) {
      this.$emit("getPortfolioOptions", this.User.Nationality);
    }
  },

  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    email_valid() {
      return (v) =>
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          v
        ) || this.$t("format_invalid");
    },

    accent_valid() {
      return !/[À-ÖØ-öø-ÿ]/.test(this.User.Email);
    },
    verifyAllPasswordRequirements() {
      return this.$refs.passwordChecklist.checkAllRequirements();
    },
    min18Years() {
      return () =>
        (this.date != null &&
          moment().diff(
            moment([
              parseInt(this.date.split("/")[2]),
              parseInt(this.date.split("/")[1]) - 1,
              parseInt(this.date.split("/")[0]),
            ]),
            "years"
          ) >= 18) ||
        this.$t("min_18");
    },
    valid_date() {
      return () =>
        (this.date &&
          parseInt(this.date.split("/")[2]) > 1600 &&
          moment([
            parseInt(this.date.split("/")[2]),
            parseInt(this.date.split("/")[1]) - 1,
            parseInt(this.date.split("/")[0]),
          ]).isValid()) ||
        this.$t("valid_date");
    },
  },
  watch: {
    nationalitySelected() {
      if (this.nationalitySelected) {
        this.$emit("getPortfolioOptions", this.User.Nationality);
      }
    },
  },
  methods: {
    checkInvestorFormData() {
      const investorFormDataLocalStorage =
        localStorage.getItem("investorFormData");
      if (investorFormDataLocalStorage) {
        const investorFormData = JSON.parse(investorFormDataLocalStorage);

        const decodedData = Buffer.from(
          investorFormData["form_data"],
          "base64"
        ).toString("binary");
        const parsedDecodedData = JSON.parse(decodedData);
        const parsedName = parsedDecodedData.name.split(" ");
        if (parsedName.length > 1) {
          this.User.LastName = parsedName[1];
        }
        this.User.Name = parsedName[0];
        this.User.Email = parsedDecodedData.email;
        this.User.CelPhone = this.parsePhoneNumber(parsedDecodedData.phone);
        this.date = parsedDecodedData.birthDate;
        this.User.Birth = this.parseDate(parsedDecodedData.birthDate);
        this.User.SocialNumber = parsedDecodedData.documentNumber;
        this.User.Nationality = parsedDecodedData.nationality;
      }
    },

    parsePhoneNumber(number) {
      return number.substring(3);
    },
    goToLogin() {
      this.$router.push("/auth");
    },
    fieldBecomeEmpty() {
      if (this.User.DocumentType == this.documentTypeEnum.select) {
        this.User.SocialNumber = "";
        this.User.DocumentNumber = "";
      }
      if (this.User.DocumentType == this.documentTypeEnum.cpf) {
        this.User.DocumentNumber = "";
      }
      if (this.User.DocumentType == this.documentTypeEnum.passport) {
        this.User.SocialNumber = "";
      }
    },
    verifyUserNationality(e) {
      if (e.alpha3Code !== "BRA") {
        this.docsType = [
          { text: "CPF", value: 1 },
          { text: "passport", value: 2 },
        ];
      } else {
        this.docsType = [{ text: "CPF", value: 1 }];
      }
    },
    verifyRequirements() {
      this.$refs.passwordChecklist.verifyRequirements();
      this.$refs.password.validate();
    },
    translateDocsType(item) {
      return this.$t(item.text);
    },
    async juridical_type_changed() {
      this.User.LastName = null;
      this.check_date();
      await this.check_social_number();
      if (this.User.JuridicalType == this.JuridicalTypeEnum.LegalPerson) {
        this.nationalitySelected = this.countries.filter(
          (x) => x.name == "Brazil"
        )[0];
        this.User.Nationality = "Brazil";
      }
    },
    async check_email() {
      if (
        !this.vs.email_valid(this.User.Email) ||
        !this.vs.accent_valid(this.User.Email)
      ) {
        this.emailFormatInvalid = true;
        return;
      } else {
        this.emailFormatInvalid = false;
      }
      this.validatingEmail = true;
      this.emailExists = await this.vs.check_if_email_exists(
        this.User.Id,
        this.User.Email,
        true
      );
      this.validatingEmail = false;
    },
    check_date() {
      if (this.valid_date() != true) {
        this.isDateValid = false;
        return;
      }
      this.isDateValid = true;
      if (
        this.User.JuridicalType == this.JuridicalTypeEnum.NaturalPerson &&
        this.min18Years() != true
      ) {
        this.isDateAboveLegal = false;
        return;
      }
      this.isDateAboveLegal = true;
    },
    parseDate(date) {
      if (!date) return null;
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    async check_social_number() {
      let onlyDigits = true;

      // this.socialNumberFormatInvalid = false;
      // if (!this.social_number_valid) {
      //   this.socialNumberFormatInvalid = true;
      //   return;
      // }
      if (this.User.DocumentType == this.documentTypeEnum.cpf) {
        if (this.User.SocialNumber.length < 14) {
          return;
        }
      } else if (this.User.DocumentType == this.documentTypeEnum.passport) {
        onlyDigits = false;

        if (this.User.SocialNumber.length < 7) {
          return;
        }
      }
      this.validatingSocialNumber = true;
      this.socialNumberExists = await this.vs.check_if_social_number_exists(
        this.User.Id,
        this.User.SocialNumber,
        "user",
        onlyDigits
      );
      if (this.socialNumberExists) {
        this.socialNumberDialog = true;
      }
      this.validatingSocialNumber = false;
      if (this.socialNumberExists) {
        this.socialNumberDialog = true;
      }
    },
    nationality_code_changed(e) {
      this.verifyUserNationality(e);
      if (this.nationalitySelected.name) {
        this.User.Nationality = this.nationalitySelected.name;
        this.$emit("getPortfolioOptions", this.nationalitySelected.name);
      }
    },
    country_code_changed() {
      if (this.User.CelPhone) {
        this.User.CelPhone = "";
      }
    },
    checkForm() {
      var result = this.$refs.form.validate();

      if (this.validatingSocialNumber || this.validatingEmail) {
        return;
      }

      // só chama o next_step se a senha for válida
      if (!this.$refs.passwordChecklist.checkFullPasswordIsValid()) return;

      if (
        result &&
        !this.socialNumberExists &&
        !this.emailExists &&
        !this.emailFormatInvalid &&
        !this.socialNumberDialog &&
        this.isDateAboveLegal &&
        this.isDateValid
      ) {
        this.User.CountryCode = this.countryCode;

        this.$gtag.event("investor_info", {
          event_category: "investor_register",
          event_label: this.User.Nationality,
          value: 0,
        });

        mixpanel.track("InvestorRegister_Start", {
          partner_id: this.User.PartnerId,
          banker_id: this.User.BankerId,
          user_name: this.User.Name,
          user_email: this.User.Email,
          user_type: "investor",
          nationality: this.User.Nationality,
          user_document_id: this.User.SocialNumber,
          phone: this.User.CelPhone,
        });

        this.$emit("next");
      }
    },
  },
};
</script>
